import axios from 'axios';

const api = axios.create({
	baseURL: '//sensetab.co.kr/api', 
  headers: {
    'Content-Type': 'application/json', 
  },
});

export const get = async (url, params) => {
  try {
    const response = await api.get(url, { params });
    return response.data;
  } catch (error) {
    console.error('API request error:', error);
    // 오류 처리
    throw error;
  }
};

export const post = async (url, data) => {
  try {
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
    // 오류 처리
    throw error;
  }
};

export const put = async (url, data) => {
  try {
    const response = await api.put(url, data);
    return response.data;
  } catch (error) {
    // 오류 처리
    throw error;
  }
};

export const patch = async (url, data) => {
  try {
    const response = await api.patch(url, data);
    return response.data;
  } catch (error) {
    // 오류 처리
    throw error;
  }
};

export const del = async (url) => {
  try {
    const response = await api.delete(url);
    return response.data;
  } catch (error) {
    // 오류 처리
    throw error;
  }
};
