import React from 'react';
import { Link } from 'react-router-dom';
import style from '../../assets/css/common.css'
import { sideBanner01, sideBanner02, sideBanner03, sideBanner04, m_sideBanner } from '../../assets/images/SideBanner/sideBanner'

const SideBannerModal = () => {
  
  return (
    <>
      <div className='sideBanner'>
        <Link to={'http://rfemfo.co.kr/Printer/Card_Print.asp'} target="_blank"><img src={sideBanner01} alt='카드제작'/></Link>
        <Link to={'http://rfemfo.co.kr/Tag/MakeTAG.asp'} target="_blank"><img src={sideBanner02} alt='태그제작'/></Link>
        <Link to={'http://sensetab.co.kr/inquiry/insert'} target="_blank"><img src={sideBanner03} alt='구매견적문의'/></Link>
        <Link to={'https://blog.naver.com/emfosky'} target="_blank"><img src={sideBanner04} alt='블로그'/></Link>
      </div>

      <div className='sideBannerMobile'>
        <Link to={'http://sensetab.co.kr/inquiry/insert'} target="_blank"><img src={m_sideBanner} alt='구매견적문의'/></Link>
      </div>

    </>
  );
};

export default SideBannerModal;