import React, { useEffect, useState } from 'react';
import { get } from '../../utils/api'
import { useParams, Link } from 'react-router-dom';
import Moment from 'moment';
import style from '../../assets/css/commonListPage.module.css'
import '../../assets/css/common.css';
import CustomerMenuModal from '../../components/Modal/CustomerMenuModal';

const NoticeDetailPage = () => {

  const [noticeDetail, setNoticeDetail] = useState([]);
  let {ntIdx} = useParams();

  useEffect(() => {
    const ntDetailData = async () => {
      try {
        const response = await get('/notice/noticeDetail', ntIdx);
        if (response.length > 0) {
          setNoticeDetail(response[0]);
          console.log(response[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    ntDetailData();
  }, []);

  noticeDetail.NT_INPUT_DATE = Moment(noticeDetail.NT_INPUT_DATE).format('YYYY-MM-DD');

  return (
    <>
    <div className={style.mainTitleDiv}>
        <h2 className={style.mainTitle}>고객센터</h2>
    </div>
    <CustomerMenuModal />
    
    <div className={style.container}>
      <div className={style.title}>공지사항</div>
      <table className={style.detailTable}>
        <thead>
          <tr>
            <th className='w-20'>제목</th>
            <th className='w-80'>{noticeDetail.NT_TITLE}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className='w-20'>내용</td>
            <td className='w-80' dangerouslySetInnerHTML={{ __html: noticeDetail.NT_CONTENT }}></td>

            {/* <td className='w-80'>
              {noticeDetail.NT_CONTENT && noticeDetail.NT_CONTENT.split('<br/>').map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  <br />
                </React.Fragment>
              ))}
            </td> */}
          </tr>
        </tbody>
      </table>
      
      <div className={style.backBtnDiv}>
          <button className={style.backBtn}><Link to={'/notice/list'}>목록으로 돌아가기</Link></button>
      </div>

    </div>
  </>
  );

};

export default NoticeDetailPage;