import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { post } from '../../../utils/api';
import style from './ProductFileDownLoadPage.module.css';

const ProductFileDownLoad = () => {

  const SERVER_URL = "https://sensetab.co.kr/";
  const [filePassword, setFilePassword] = useState('');
  let {idx} = useParams();

  const onPasswordHandler = (e) => {
    setFilePassword(e.currentTarget.value);
  };

  const onSubmitHandler = async () => {
    try{
      await post('/product/fileDownload', {
        prdIdx: idx,
        password: filePassword
      })
      .then((response) => {
        if(response.result) {
          //zip 파일만 가능
          window.location.href = SERVER_URL + response.path;
        } else {
          alert('틀린 비밀번호를 입력하였습니다.');
        }
      }); 
    } catch (error) {
      console.log(error);
    };
  };

  return (
    <div className={style.prdDlWrap}>
      <div id={style.prdDlTitle}>
        리더기 제품정보 확인 및 비밀번호 입력 후 <span className='blue-font'>다운로드</span> 하실 수 있습니다.
      </div>
      <div id={style.prdDlContent}>
        <div>
          이 글은 비밀글 입니다.
        </div>
        <div>
          비밀번호를 입력하여 주시기 바랍니다.
        </div>
      </div>
      <input type='password' placeholder='비밀번호' id={style.prdDlPwdInput} onChange={onPasswordHandler}/>
      <button id={style.prdDlSubmitBtn} onClick={onSubmitHandler}>확인</button>
    </div>
  );

};

export default ProductFileDownLoad;
