import React from 'react';
import { Link } from 'react-router-dom';

import style from '../../MainPage/MobileMainPage.module.css'

import { section01, section02, section03, section04, section05, section06, section07, section08 } from '../../../assets/images/mainImagesMobile'
import SideBannerModal from '../../../components/Modal/SideBannerModal';

const ProductIntroPage = () => {
  
    const divStyle={
        textAlign: "center",
        margin: "0 auto",
        width: "100%",
        marginTop: "10px",
    };

    const imgTextStyle={
        textAlign: "center",
        padding: "10px",
        margin: "0 auto",
        width: "100%",
    };

    const imgStyle={
      textAlign: "center",
      margin: "0 auto",
      marginBottom: "30px",
      width: "100%",
  };


  return (
    <>
      <div id={style.mainContainer}>
        
      <SideBannerModal />
        
        <div style={divStyle}>
          <img src={section01} alt="제품소개이미지1" style={imgTextStyle} />
          <img src={section02} alt="제품소개이미지2" style={imgStyle} />
          <img src={section03} alt="제품소개이미지3" style={imgStyle} />
          <img src={section04} alt="제품소개이미지4" style={imgStyle} />
          <img src={section05} alt="제품소개이미지5" style={imgStyle} />
          <img src={section06} alt="제품소개이미지6" style={imgStyle} />
          <img src={section07} alt="제품소개이미지7" style={imgStyle} />
          <img src={section08} alt="제품소개이미지8" style={imgTextStyle} />
        </div>
      </div>
    </>
    
  );

};

export default ProductIntroPage;
