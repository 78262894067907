import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import style from './ProductCard.module.css'
import { get } from '../../utils/api';

const ProductCard = ({prdIdx, prdName, prdThumbnail, serverUrl}) => {
  
  const [specList, setSpecList] = useState([]);

  const prdSpecList = async () => {
    try{ 
      const response = await get('/product/specList', {
        prdIdx: prdIdx
      })
      setSpecList(response);
    } catch (error) {
      console.log(error);
    };
  }

  const clickDownload = async () => {
    try{
      window.location.href = `/product/download/${prdIdx}`;
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    prdSpecList();
  }, [prdIdx]);

  return (
    <div id={'prd-' + prdIdx} className={style.prdCardWrap} key={prdIdx}>
      <div>
        <Link to={`/product/detail/${prdIdx}`} >
          <img src={serverUrl + prdThumbnail} alt={prdName} className={style.prdCardThumbImg}/>
        </Link>
        <div onClick={clickDownload} className={style.prdCardDownloadText}>
          <div></div>  
        </div>
        {/* <div  className={style.prdCardDownBrc}>  */}
        {/* onClick={clickBrcDown} */}
          <a href={'https://sensetab.co.kr/api/product/brcDownload?prdIdx=' + prdIdx} className={style.prdCardDownBrc}></a>  
        {/* </div> */}
      </div>
    </div>
  );

};

export default ProductCard;