import React, { useEffect, useState } from 'react';
import { get } from '../../utils/api'
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import Moment from 'moment';
import style from '../../assets/css/commonListPage.module.css'
import '../../assets/css/common.css';
import CustomerMenuModal from '../../components/Modal/CustomerMenuModal';

const FaqDetailPage = () => {

    const [faqDetail, setFaqDetail] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const onClickBtn = () => {
        navigate(-1);
    };

    const queryParams = new URLSearchParams(location.search);
    // const cateName = queryParams.get('cateName');
    // const faqIdx = queryParams.get('faqIdx');
    let {faqIdx} = useParams();

    // useEffect(() => {
    //     const faqDetailData = async() => {
    //         try {
    //             // await get(`/faq/faqDetail?faqCate=${cateName}&faqIdx=${faqIdx}`)
    //             await get(`/faq/faqDetail?faqIdx=${faqIdx}`)
    //             .then((response) => {
    //                 setFaqDetail(response[0]);
    //                 console.log(response[0]);
    //             });
    //         } catch (err) {
    //             console.log(err);
    //         };
    //     }
    //     faqDetailData();
    // }, [faqIdx]);

    useEffect(() => {
        const faqDetailData = async () => {
            try {
                const response = await get('/faq/faqAllDetail', faqIdx);
                if (response.length > 0) {
                    setFaqDetail(response[0]);
                    console.log(response[0]);
                }
            } catch (err) {
                console.log(err);
            }
        };
        faqDetailData();
    }, [faqIdx]);

    faqDetail.FAQ_INPUT_DATE = Moment(faqDetail.FAQ_INPUT_DATE).format('YYYY-MM-DD');

    return (
        <>
        <div className={style.mainTitleDiv}>
            <h2 className={style.mainTitle}>고객센터</h2>
        </div>
        <CustomerMenuModal />

        <div className={style.container}>
            <div className={style.title}>FAQ</div>
            <table className={style.detailTable}>
                <thead> 
                    <tr>
                        <th className='w-20'>제목</th>
                        <th className='w-80'>{faqDetail.FAQ_TITLE}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='w-20'>내용</td>
                        <td className='w-80'>
                        {faqDetail.FAQ_CONTENT && faqDetail.FAQ_CONTENT.split('<br/>').map((item, index) => (
                            <React.Fragment key={index}>
                            {item}
                            <br />
                            </React.Fragment>
                        ))}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className={style.backBtnDiv}>
                <button className={style.backBtn} onClick={onClickBtn}><a>목록으로 돌아가기</a></button>
            </div>

        </div>
        </>
    );
};

export default FaqDetailPage;