import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from './MobileHeader.module.css';

const MobileHeader = () => {

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false); // 새로운 상태 추가
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showSearchInput]);
  
  const handleClickOutside = (event) => {
    if (showSearchInput && !event.target.classList.contains('searchElement')) {
      setShowSearchInput(false);
    }
    if (showSubMenu && !event.target.classList.contains('customerSubMenu')) {
      setShowSubMenu(false);
    }
  };

  const searchClickHandler = () => {
    setShowSearchInput(true);
  };

  const closeNavSubMenu = () => {
    setShowSubMenu(false);
  };

  const openNavSubMenu = () => {
    setShowSubMenu(true);
  };

  const SearchElement = () => {
    return (
      <>
        <input 
          type='text' 
          id={style.searchInput} 
          className='searchElement submitVal'
        />
      </>
    );
  };

  const onSubmitHandler = () => {
    let submitVal = document.querySelector('.submitVal').value;
    navigate('/product/list?searchVal=' + submitVal);
    // window.location.href = `/product/list?searchVal=${submitVal}`;
  };

  const customMenuStyle = {
    backgroundColor: showSubMenu ? '#000000' : '#ffffff',
    color: showSubMenu ? '#ffffff' : '#000000'
  };

  return (
    <header id={style.mobileHeaderWrap}>
      
      <div id={style.headerLogo}>
        <Link to={'/'}>
          <img src='/images/main/main-logo.svg' id={style.mainLogo}></img>
        </Link>
        <div id={style.searchWrap} className='searchElement'>
          <div id={style.searchInputDiv}>
            {showSearchInput && <SearchElement />}
          </div>
          <img 
            src='/images/main/mobile-search-icon.svg' 
            id={style.searchIcon} className='searchElement' 
            onClick={searchClickHandler}
            style={showSearchInput ? {display: 'none'} : {display: ''}}
          />
          <img 
            src='/images/main/arrow.svg' 
            id={style.searchIcon} 
            className='newElement' 
            onClick={onSubmitHandler} 
            style={showSearchInput ? {display: '', padding: '3%'} : {display: 'none'}}
          />  
        </div>
      </div>
      <div>
        <div id={style.navMenu}>
          <Link to={'http://emfoplus.co.kr/'} target="_blank" onMouseEnter={closeNavSubMenu}><li>회사소개</li></Link>
          <Link to={'/product/intro'} onMouseEnter={closeNavSubMenu}><li>제품소개</li></Link>
          <Link to={'/product/list'} onMouseEnter={closeNavSubMenu}><li>제품검색</li></Link>
          <Link to={'/inquiry/insert'} onMouseEnter={closeNavSubMenu}><li>구매/견적문의</li></Link>
          <a onMouseEnter={openNavSubMenu}><li style={customMenuStyle}>고객센터</li>
          <div>
          {showSubMenu  && (
          <ul className={style.customerSubMenu} onMouseLeave={closeNavSubMenu}>
            <Link to={'/notice/list'}><li>공지사항</li></Link>
            <Link to={'/faq/faqAllList'}><li>FAQ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li></Link>
            <Link to={'/remotesupport'}><li>원격지원</li></Link>
            <Link to={'http://www.rfemfo.co.kr/Custom/Buy.asp'} target="_blank"><li>결제하기</li></Link>
          </ul>
          )}
          </div>
          </a>
          <Link to={'https://smartstore.naver.com/1s4s?NaPm=ct%3Dlkvxzp8v%7Cci%3Dcheckout%7Ctr%3Dds%7Ctrx%3Dnull%7Chk%3D929ddb1515903c39e28ababba0346305504e4da3'} target="_blank" onMouseEnter={closeNavSubMenu}>
            <li>쇼핑몰</li>
          </Link>
          <Link to={'http://www.rfemfo.co.kr/main.asp?'} target="_blank" onMouseEnter={closeNavSubMenu}><li><span className='blue-font'>엠포플러스</span></li></Link>
        </div>
        {/* <div id={style.navSubMenu}>
          <div onMouseEnter={closeNavSubMenu}></div>
          <div onMouseEnter={closeNavSubMenu}></div>
          <div onMouseEnter={closeNavSubMenu}></div>
          <div onMouseEnter={closeNavSubMenu}></div>
          <div onMouseEnter={closeNavSubMenu}></div>
          
          <div onMouseEnter={closeNavSubMenu}></div>
        </div> */}
      </div>
    </header>
  );
};

export default MobileHeader;