import { get, post } from '../../utils/api'

export const fetchBuyListData = async (curPage) => {
    try {
        const response = await get('/buy', {
            pageIdx: curPage
        });
        return response;
    } catch (error) {
        console.log(error);  
        return [];
    };
};

export const fetchBuyCntData = async () => {
    try {
        const response = await get('/buy/cnt');
        return response;
    } catch (error) {
        console.log(error);  
        return [];
    };
};

export const fetchBuyAcntData = async (idx) => {
    try {
        const response = await get('/buy/account', {
            idx : idx
        });
        return response;
    } catch (error) {
        console.log(error);  
        return [];
    };
};

export const fetchBuyInsertData = async (data) => {
    try {
        console.log("2차: ", data);

        const response = await post('/buy/insert', {
            data: data
        });
        return response;
    } catch (error) {
      console.error('오류 발생:', error);
      alert('오류가 발생했습니다. 다시 시도해주세요.');
    }
};
