const cpNameInput = /[^ㄱ-ㅎ가-힣\w\s]$/;
const staffNameInput = /[^ㄱ-ㅎ가-힣\w]$/;
const phoneInput = /^\d*$/;

// const emailInput = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
// const emailInput = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z0-9-]{4,10})$/;
// const emailInput = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;
const emailInput = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

// const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const quanInput = /^\d*$/;

export const valueCpName= (input) => {
  if (cpNameInput.test(input)) {
    return '한글 또는 영문만 입력 가능합니다.';
  }
  if (input.length > 100) {
    return '최대 100자까지만 입력 가능합니다.';
  }
  return '';
};

export const valueStaffName= (input) => {
  if (staffNameInput.test(input)) {
    return '한글 또는 영문만 입력 가능합니다.';
  }
  if (input.length > 100) {
    return '최대 100자까지만 입력 가능합니다.';
  }
  return '';
};

export const valuePhone = (input) => {
    if (!phoneInput.test(input)) {
      return '숫자만 입력 가능합니다.';
    }
    if (input.length > 11) {
      return '최대 11자리까지만 입력 가능합니다.';
    }
    return '';
};

export const valueEmail = (input) => {
    if (emailInput.test(input)) {
      console.log(input);
      return '이메일 형식이 일치하지 않습니다.';
    }
    if (input.length > 100) {
      return '최대 100자까지만 입력 가능합니다.';
    }
    return '';
};

export const valueUse = (input) => {
  if (input.length > 200) {
    return '최대 200자까지만 입력 가능합니다.';
  }
  return '';
};

export const valuePrd = (input) => {
  if (input.length > 100) {
    return '최대 100자까지만 입력 가능합니다.';
  }
  return '';
};

export const valueQuan = (input) => {
  if (!quanInput.test(input)) {
    console.log(input);
    return '숫자만 입력 가능합니다.';
  }
  if (input.length > 11) {
    return '최대 11자리까지만 입력 가능합니다.';
  }
  return '';
};

export const valueContent = (input) => {
  if (input.length > 2000) {
    return '최대 2000자까지만 입력 가능합니다.';
  }
  return '';
};


export const isValidEmail = (email) => {
  return emailInput.test(email);
};
