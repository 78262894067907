import React from 'react';
import './Pagination.css';
import prevBtn from '../../assets/images/prevBtn.png'
import nextBtn from '../../assets/images/nextBtn.png'


const Pagination = ({ currentPage, totalPages, onPageChangeHandler }) => {
  const handlePrevButtonClick = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      onPageChangeHandler(prevPage);
    }
  };

  const handleNextButtonClick = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      onPageChangeHandler(nextPage);
    }
  };

  const renderButtons = () => {
    const buttons = [];
    let startPage = currentPage - 2;
    let endPage = currentPage + 2;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(5, totalPages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          id="page-btn"
          key={i}
          onClick={() => onPageChangeHandler(i)}
          className={i === currentPage ? 'active' : ''}
          value={i}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="pagination-buttons">
      <button
        id="prev-btn"
        onClick={handlePrevButtonClick}
        className={currentPage === 1 ? 'd-none' : ''}
      >
        <img src={prevBtn} alt="" />
      </button>
      {renderButtons()}
      <button
        id="next-btn"
        onClick={handleNextButtonClick}
        className={currentPage >= totalPages ? 'd-none' : ''}
      >
        <img src={nextBtn} alt="" />
      </button>
    </div>
  );
};

export default Pagination;
