import React from 'react';
import { Link } from 'react-router-dom';
import style from '../../assets/css/commonListPage.module.css'
import rsStyle from './RemoteSupportPage.module.css';
import CustomerMenuModal from '../../components/Modal/CustomerMenuModal';

const RemoteSupportPage = () => {

  const handleOpenNewTab = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  }

  return (
    <>
      <div className={style.mainTitleDiv}>
        <h2 className={style.mainTitle}>고객센터</h2>
      </div>
      <CustomerMenuModal />
    
      <div className={style.container}>
        <div className={style.title}>원격지원 진행절차</div>
            <div className={rsStyle.rsbody}>
                <a> - 엠포플러스(주)에서 고객님의 불만을 덜어드리기 위하여 원격지원 서비스를 제공합니다.</a>
                <a> - 원격지원 신청시 먼저 고객센터 (1599-2320)을 통해 담당직원과 통화한 후 신청해 주시기 바랍니다.</a>
                <a> - 원격지원 가능 시간 : 평일 09 : 00 ~ 18 : 00</a>
                <a> - 아래 버튼을 클릭하여 원격지원 프로그램을 실행해 주시기 바랍니다.</a>
            </div>
            <div className={rsStyle.rsBtnDiv}>
                <button className={rsStyle.rsBtn} onClick={() => handleOpenNewTab("http://emfoplus.co.kr/cscenter/remote")}>원격지원 프로그램 실행</button>
            </div>
      </div>
    </>
  );

};

export default RemoteSupportPage;
