import React from 'react';
import style from './Footer.module.css'
import logoImage from '../../assets/images/logo.png'
import logoHanImg from '../../assets/images/logoHan.png'
import certLogo from '../../assets/images/certLogo.png'
import mobileLogo from '../../assets/images/mobileLogo.png'
import mobileLogoHan from '../../assets/images/mobileLogoHan.png'

const Footer = () => {

    const goPopup1 = () => {
      const url = 'http://privacy.emfo.co.kr/06_rfemfo/privacy.emfo?pg=2'
      const options = 'width=980, height=700, scrollbars=yes, resizable=no';
      window.open(url, "_blank", options);
    }
    const goPopup2 = () => {
      const url = 'http://privacy.emfo.co.kr/06_rfemfo/privacy.emfo?pg=1'
      const options = 'width=980, height=700, scrollbars=yes, resizable=no';
      window.open(url, "_blank", options);
    }

    return (
      <>
      
      <div className={style.footerWrapper}>
        <div className={style.footer}>
          <div className={style.logo}>
            <img src={logoImage} alt="Logo" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={logoHanImg} alt="LogoHan" />
          </div>

          <div className={style.introduceAll}>
            <div className={style.introduce}>
              <p className={style.title}>CALL CENTER</p>
              <p className={style.mid}>고객센터<br></br>1599-2320</p>
              <p className={style.sub}>월-금 / 09:00 - 18:00 (점심 11시 50분 - 12시 50분)<br></br>토, 일요일 공휴일은 휴무이며 일반 상담만 가능합니다.</p>
            </div>
            <div className={style.introduce}>
              <p className={style.title}>BANKING</p>
              <p className={style.mid}>국민은행<br></br>6756-9085-8472-27 </p>
              <p className={style.sub}>예금주 : 엠포플러스(주)</p>
            </div>
            <div className={style.introduce}>
              <p className={style.title}>E-Mail</p>
              <p className={style.mid}>rfid@emfoplus.co.kr</p>
            </div>
          </div>
        </div>
      </div>

      <div className={style.footerWrapper2}>
        <div className={style.footer}>
          <div className={style.address}>
            <p className={style.sub}>
                <b>엠포플러스(주)</b><br></br>서울시특별시 성동구 아차산로17길 48 SK V1센터 1동 16F (04799) | 대표이사: 이정훈 | 사업자등록번호: 206-86-34586
            </p>
            <p className={style.sub}>
                통신판매업신고: 제2020-서울성동-02947호 | 개인정보관리책임자: 윤창호 &nbsp;
                <a href="http://emfoplus.co.kr/index.do" target="_blank">회사소개</a><span>  | </span>
                <a onClick={goPopup1}>이용약관</a><span>  | </span>
                <a onClick={goPopup2}>개인정보처리방침</a>
            </p>
            <div className={style.certLogo}>
              <img src={certLogo} alt="Logo" />
            </div>
          </div>
        </div>
      </div>

      <div className={style.mobileFooter}>
        <p className={style.mobileLink}>
          <a href="http://emfoplus.co.kr/index.do" target="_blank">회사소개</a><span>  | </span>
          <a onClick={goPopup1}>이용약관</a><span>  | </span>
          <a onClick={goPopup2}>개인정보처리방침</a>
        </p>
        <p className={style.mobileMid}>고객센터 1599-2320<br></br>국민은행 6756-9085-8472-27 예금주 : 엠포플러스(주)</p>
        <p className={style.mobileSub}>월-금 / 09:00 - 18:00 (점심 11시 50분 - 12시 50분)<br></br>토, 일요일 공휴일은 휴무이며 일반 상담만 가능합니다.</p>
        <p className={style.mobileSub}>메일 rfid@emfoplus.co.kr</p>
        <p className={style.mobileSub}><b>엠포플러스(주)</b><br></br>서울시특별시 성동구 아차산로17길 48 SK V1센터 1동 16F (04799)<br></br>대표이사: 이정훈 사업자등록번호: 206-86-34586<br></br>통신판매업신고: 제2020-서울성동-02947호<br></br>개인정보관리책임자: 윤창호</p>
        <div className={style.mobileLogo}>
          <img src={mobileLogo} alt="Logo" />
            &nbsp;&nbsp;&nbsp;&nbsp;
          <img src={mobileLogoHan} alt="LogoHan" />
        </div>
      </div>
      </>
    );
  };

export default Footer;
