import React from 'react';
import { Link } from "react-router-dom"

import style from './MobileProductCard.module.css'

const ProductCard = ({prdIdx, prdName, prdThumbnail, serverUrl}) => {

  const clickDownload = async () => {
    try{
      window.location.href = `/product/download/${prdIdx}`;
    } catch (error) {
      console.log(error);
    };
  };

  return (
    <div id={'prd-' + prdIdx} className={style.prdCardWrap} key={prdIdx}>
      <div>
        <Link to={`/product/detail/${prdIdx}`} >
          <img src={serverUrl + prdThumbnail} alt={prdName} className={style.prdCardThumbImg}/>
        </Link>
        <div onClick={clickDownload} className={style.prdCardDownloadText}>
          <div></div>  
        </div>
        <a href={'https://sensetab.co.kr/api/product/brcDownload?prdIdx=' + prdIdx} className={style.prdCardDownBrc}></a>  
        {/* <div className={style.prdCardDownBrc}>
          <div></div>  
        </div> */}
      </div>
    </div>
  );

};

export default ProductCard;