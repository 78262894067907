import React from "react";
import { Route, Routes } from "react-router-dom";
import { useMediaQuery } from "react-responsive";


import BuyOrderPage from "../../pages/BuyPage/BuyOrderPage";


import ProductDetailPage from "../../pages/Product/ProductDetailPage/ProductDetailPage";
import ProductListPage from "../../pages/Product/ProductListPage/ProductListPage";
import MobileProductListPage from "../../pages/Product/ProductListPage/MobileProductListPage";
import MobileProductDetailPage from "../../pages/Product/ProductDetailPage/MobileProductDetailPage";
import NoticeListPage from "../../pages/NoticePage/NoticeListPage";
import NoticeDetailPage from "../../pages/NoticePage/NoticeDetailPage";
import FaqListPage from "../../pages/FaqPage/FaqListPage";
import FaqDetailPage from "../../pages/FaqPage/FaqDetailPage";
import InquiryInsertPage from "../../pages/InquiryPage/InquiryInsertPage";
import InquiryResultPage from "../../pages/InquiryPage/InquiryResultPage";
import RemoteSupportPage from "../../pages/RemoteSupport/RemoteSupportPage";
import ProductIntroPage from "../../pages/Product/ProductIntroPage/ProductIntroPage";
import MobileProductIntroPage from "../../pages/Product/ProductIntroPage/MobileProductIntroPage";


import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Main from "../../pages/MainPage/MainPage";
import MobileMain from "../../pages/MainPage/MobileMainPage";
import '../../assets/css/common.css';
import MobileHeader from "../Header/MobileHeader";
import ProductFileDownLoadPage from "../../pages/Product/ProductFileDownLoadPage/ProductFileDownLoadPage";
import MobileProductFileDownLoadPage from "../../pages/Product/ProductFileDownLoadPage/MobileProductFileDownLoadPage";

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const Layout = () => {
  
  return(
    <>
      <Default>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/product/list" element={<ProductListPage />} />
            <Route path="/notice/list" element={<NoticeListPage />} />
            <Route path="/notice/detail/:ntIdx" element={<NoticeDetailPage />} />
            <Route path="/faq/list/:cateName" element={<FaqListPage />} />
            <Route path="/faq/detail" element={<FaqDetailPage />} />
            <Route path="/faq/faqAllList" element={<FaqListPage />} />
            <Route path="/faq/faqAllDetail/:faqIdx" element={<FaqDetailPage />} />
            <Route path="/inquiry/insert" element={<InquiryInsertPage />} />
            <Route path="/inquiry/success" element={<InquiryResultPage />} />
            <Route path="/remotesupport" element={<RemoteSupportPage />} />
            <Route path="/product/detail/:idx" element={<ProductDetailPage />} />
            <Route path="/product/download/:idx" element={<ProductFileDownLoadPage />} />
            <Route path="/product/intro" element={<ProductIntroPage/>} />
            
            <Route path="/buy/order" element={<BuyOrderPage/>} />
          </Routes>
        </div>
        <Footer/>
      </Default>
      <Mobile>
        <MobileHeader />
        <Routes>
            <Route path="/" element={<MobileMain />} />
            <Route path="/product/detail/:idx" element={<MobileProductDetailPage />} />
            <Route path="/product/download/:idx" element={<MobileProductFileDownLoadPage />} />
            <Route path="/product/list" element={<MobileProductListPage />} />
            <Route path="/notice/list" element={<NoticeListPage />} />
            <Route path="/notice/detail/:ntIdx" element={<NoticeDetailPage />} />
            <Route path="/faq/list/:cateName" element={<FaqListPage />} />
            <Route path="/faq/detail" element={<FaqDetailPage />} />
            <Route path="/faq/faqAllDetail" element={<FaqDetailPage />} />
            <Route path="/faq/faqAllDetail/:faqIdx" element={<FaqListPage />} />
            <Route path="/inquiry/insert" element={<InquiryInsertPage />} />
            <Route path="/inquiry/success" element={<InquiryResultPage />} />
            <Route path="/remotesupport" element={<RemoteSupportPage />} />
            <Route path="/product/intro" element={<MobileProductIntroPage/>} />
          </Routes>
          <Footer/>
      </Mobile>
    </>
  );
};

export default Layout;

