import React, { useEffect, useState } from 'react';
import { get } from '../../utils/api';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import Pagination from '../../components/Paging/Pagination';
import style from '../../assets/css/commonListPage.module.css'
import '../../assets/css/common.css';
import CustomerMenuModal from '../../components/Modal/CustomerMenuModal';

import SideBannerModal from '../../components/Modal/SideBannerModal';

const NoticeListPage = () => {

  const [noticeList, setNoticeList] = useState([]);
  const [pagingInfo, setPagingInfo] = useState([]);
  const [curPage, setCurPage] = useState(1);

  const noticeData = async() => {
    try {
      const response = await get('/notice/noticeList', {
        pageIdx: curPage
      });
      setNoticeList(response.noticeList);
      setPagingInfo(response.pageInfo);
    } catch(error) {
      console.log(error);
    };
  };

  useEffect(() => {
    noticeData();
  }, [curPage]);

  // noticeData();

  const showNoticeList = (ntIdx, ntTitle, ntInputDate) => {
  
    ntInputDate = Moment(ntInputDate).format('YYYY-MM-DD');
    
    const boldIndexes = [435, 436, 438];
    const rowStyle = boldIndexes.includes(ntIdx) ? { fontWeight: 'bold' } : {};
    
    const redIndexes = [435, 436, 438];
    const textStyle = redIndexes.includes(ntIdx) ? { color: 'red', fontWeight: 'bold' } : {};
    const noticeText = [435, 436, 438].includes(ntIdx) ? '공지' : ntIdx;


    return (
      <tr className={style.tableList} id={'nt-' + ntIdx} key={ntIdx}>
        <td>
          <Link style={textStyle} to={`/notice/detail/${ntIdx}`}>{noticeText}</Link>
        </td>
        <td>
          <Link style={rowStyle} to={`/notice/detail/${ntIdx}`}>{ntTitle}</Link>
        </td>
        <td style={rowStyle}>{ntInputDate}</td> 
      </tr>
    );
  };

  const onPageChangeHandler = (pageNumber) => {
    // console.log(pageNumber);
    setCurPage(pageNumber);
    // noticeData();
  };

  return (
    <>
      <div className={style.mainTitleDiv}>
          <h2 className={style.mainTitle}>고객센터</h2>
      </div>

      <CustomerMenuModal />
    
      <div className={style.container}>

      <SideBannerModal />

        <div className={style.title}>공지사항</div>
        <table className={style.table}>
          <thead>
            <tr>
              <th className='w-10'>NO</th>
              <th className='w-70'>내용</th>
              <th className='w-20'>등록일</th>
            </tr>
          </thead>
          <tbody>

            {noticeList && noticeList.map((nt) => (
              showNoticeList(nt.NT_IDX, nt.NT_TITLE, nt.NT_INPUT_DATE)
            ))}
          
          </tbody>
        </table>

        <Pagination
          currentPage={pagingInfo.curPage}
          totalPages={pagingInfo.totalPage}
          onPageChangeHandler={onPageChangeHandler}
        />
      </div>
    </>
  );

};

export default NoticeListPage;
