import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../../utils/api';
import style from './ProductDetailPage.module.css'

const ProductDetailPage = () => {

  const SERVER_URL = "https://sensetab.co.kr/";
  const [product, setProduct] = useState([]);
  const [prdImg, setPrdImg] = useState([]);
  let {idx} = useParams();
  
  useEffect(() => {
    
    const prdData = async () => {
      try {
        await get(
          '/product/detail', 
          {
            idx : idx,
            type : 'P',
          }
          )
        .then((response) => {
          setProduct(response[0]);
          setPrdImg(response[1].imgs);
        });  
      } catch (error) {
        console.log(error);  
      };
    };
    prdData();
    
  }, [idx]);

  return (
    <>
      <div className={style.con}>
        <div>
          {prdImg.map((imgs) => (
            <img key={imgs.PRD_IMG_IDX} className={style.detailPrdImg}  src={SERVER_URL + imgs.PRD_IMG_PATH} alt={product.PRD_NAME} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductDetailPage;
