import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import iconv from 'iconv-lite';

import { fetchBuyInsertData } from './buyService';

const BuyOrderPage = () => {
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  
  useEffect(() => {
    console.log(location.search);
    
    const fetchData = async () => {
      try {
        const decodedSearch = decodeURIComponent(unescape(location.search));
        const queryParams = new URLSearchParams(decodedSearch);

        const payMethod = queryParams.get('PAYMETHOD');
        const cpid = queryParams.get('CPID');
        const daoutrx = queryParams.get('DAOUTRX');
        const orderNo = queryParams.get('ORDERNO');
        const productCode = queryParams.get('PRODUCTCODE');
        const amount = queryParams.get('AMOUNT');
        const settDate = queryParams.get('SETTDATE');
        const email = queryParams.get('EMAIL');
        const userId = queryParams.get('USERID');
        //const username = queryParams.get('USERNAME');
        const username = queryParams.get('USERNAME') ? iconv.decode(queryParams.get('USERNAME'), 'euc-kr') : null;
        const cardCode = queryParams.get('CARDCODE');
        //const cardName = queryParams.get('CARDNAME');
        const cardName = queryParams.get('CARDNAME') ? iconv.decode(queryParams.get('CARDNAME'), 'euc-kr') : null;
        const reservedIndex1 = queryParams.get('RESERVEDINDEX1');
        const reservedIndex2 = queryParams.get('RESERVEDINDEX2');
        const reservedString = queryParams.get('RESERVEDSTRING');
        
        console.log("cardName: ", cardName);

        const data = {
          payMethod,
          cpid,
          daoutrx,
          orderNo,
          productCode,
          amount,
          settDate,
          email,
          userId,
          username,
          cardCode,
          cardName,
          reservedIndex1,
          reservedIndex2,
          reservedString,
        };

        console.error('1st:', data);
        await fetchBuyInsertData(data);
        setSuccess(true);
      } catch (error) {
      
        console.error('오류 발생:', error);
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      }
    };

    fetchData();
  }, [location.search]);
  
  useEffect(() => {
  }, [location]);

  return (
    <div>
      {success ? (
        <>
          <h1>성공뾰로롱</h1>
          <div dangerouslySetInnerHTML={{ __html: '<html><body><RESULT>SUCCESS</RESULT></body></html>' }} />
        </>
      ) : (
        <h1>데이터 처리 중...</h1>
      )}
    </div>
  );
};

export default BuyOrderPage;