import React, { useEffect, useState, useRef } from 'react';
import { get } from '../../../utils/api'
import { Link, useLocation  } from "react-router-dom"
import MobileProductCard from '../../../components/Product/MobileProductCard';
import style from './MobileProductListPage.module.css'
import Pagination from '../../../components/Paging/Pagination';
import '../../../components/Modal/BottomSheetModal.css';
import { m_listImg01, m_listImg02, m_listImg03, noneList } from '../../../assets/images/PrdListPage/prdList';
import SideBannerModal from '../../../components/Modal/SideBannerModal';

const MobileProductListPage = () => {

  const SERVER_URL = "https://sensetab.co.kr/";
  const [product, setProduct] = useState([]);
  const [spec, setSpec] = useState([]);
  const [category, setCategory] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [selectedSpecName, setSelectedSpecName] = useState([]);
  const [pagingInfo, setPagingInfo] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkedCategory, setCheckedCategory] = useState(null);
  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let submitVal = searchParams.get("searchVal");

  const getFilteredProducts = async () => {
    try {
      const response = await get('/product/search', {
        searchValue: submitVal,
      });
      if (response !== false && response.length > 0) {
        setCheckedList(response.map(item => item.SPEC_IDX));
        setSelectedSpecName(response.map(item => item.SPEC_NAME));
        document.querySelector('.selectedSpecWrap').style.display = 'flex';
      } else {

      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (submitVal) {
      getFilteredProducts();
    }
  }, [submitVal]);

  useEffect(() => {
    if (checkedCategory !== null) {
      scrollToCategory(checkedCategory);
    }
    
  }, [checkedCategory]);
  
  const prdData = async () => {
    try {
      const response = await get('/product/list', {
        checkedList: checkedList, 
        pageIdx: curPage
      });
      setProduct(response.prdList);
      setSpec(response.spList);
      setPagingInfo(response.pageInfo);
      setCategory(response.ctList);
    } catch (error) {
      console.log(error);  
    };
  };

  useEffect(() => {
    prdData();
  }, [curPage, checkedList]);

  const toggleChecked = (idx, name) => {
    setCheckedList((prev) => {
      if (prev.includes(idx)) {
        return prev.filter((el) => el !== idx);
      } else {
        return [...prev, idx];
      }
    });
  
    setSelectedSpecName((prev) => {
      if (prev.includes(name)) {
        return prev.filter((el) => el !== name);
      } else {
        return [...prev, name];
      }
    });
  };

  const onPageChangeHandler = (pageNumber) => {
    setCurPage(pageNumber);
    prdData();
  };

  const onSubmitHandler = async () => {
    document.querySelector('.selectedSpecWrap').style.display = 'flex';
    closeModal();
    prdData();
    
  };

  const onResultSpecChangeHandler = () => {
    return (
      selectedSpecName.map((name, idx) => (
      <div className={style.selectedSpecDiv} key={idx}>
        {name}
        <img src='/images/product/close.svg' 
          alt='test'
          className={style.selectedClose}
          onClick={() => toggleChecked(checkedList[idx], name)}
        />
      </div>
    ))
    );
  };
  
  const openModal =  (ctIdx) => {
    document.body.style = `overflow: hidden`;
    setIsModalOpen(true);
    scrollToCategory(ctIdx);
  };

  const closeModal = () => {
    document.body.style = `overflow: auto`;
    setIsModalOpen(false);
  };
  
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const showCategory = () => {
    
    return (
      <>
        {category.map((item) => (
          item.CT_IDX === 7 ? null : (
          <div 
            key={item.CT_IDX} 
            id={'category-' + item.CT_IDX} 
            onClick={() => {handleCategoryClick(item.CT_IDX);}}
            className={`${
              checkedCategory === item.CT_IDX ? style.checkedCategory : style.nonCheckedCategory
            }`}
            >
              {item.CT_NAME}
            </div>
          )
        ))}
      </>
    );
  };

  const handleCategoryClick = (ctIdx) => {
    setCheckedCategory(ctIdx);
    openModal(ctIdx);
    scrollToCategory(ctIdx);
  };


  //바텀 시트 선택한 카테고리 스펙으로 스크롤 이동
  const scrollToCategory = (ctIdx) => {
    const categoryElement = document.getElementById(`modal-prd-ct-${ctIdx}`);

    if (categoryElement != null) {
      categoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  //바텀 시트 컴포넌트
  const BottomSheetModal = (isOpen, onClose) => {

    return (
      <div id='bottom-modal-container' className={style.modalContainer} onClick={onClose} style={{ display: isModalOpen ? 'flex' : 'none' }}>
        <div id='bottom-modal-wrap' onClick={handleContentClick}>
          <div className={style.modalHeader}>필터</div>
          <div className={style.modalContent}>
            <div className={style.modalCategory}>
              {showCategory(scrollToCategory)}
            </div>
            <div className={style.modalSpec}>
              {spec.map((item) => (
                item.CT_IDX === 7 ? null : (
                  <div className={style.modalSpecSection} key={item.CT_IDX} >
                    <div 
                      className={style.modalCtNameDiv} 
                      id={'modal-prd-ct-' + item.CT_IDX}
                    >
                      {item.CT_NAME}
                    </div> 
                    <div className={style.modalSpecDiv}>
                      {item.specs.map((spec) => ( 
                          <div 
                            key={spec.SPEC_IDX}
                            id={spec.SPEC_IDX}
                            className={`${style.modalSpecNameDiv} ${checkedList.includes(spec.SPEC_IDX) ? style.checked : ''}`}
                            value={spec.SPEC_IDX}
                            onClick={() => {
                              toggleChecked(spec.SPEC_IDX, spec.SPEC_NAME);
                            }}
                          >
                            {spec.SPEC_NAME}
                          </div>
                      ))}
                    </div>
                  </div>
                )
              ))}
            </div>
            {/* <div className={style.modalFooter}>
              <button className={style.modalSearchBtn} onClick={onSubmitHandler}>검색하기</button>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>

    <SideBannerModal />

      {BottomSheetModal(openModal, closeModal)}
      <div id='mobile-title'>제품검색</div>

      <div className={style.listImages}>
        <img src={m_listImg01}  alt='img01'/>
        <img src={m_listImg02}  alt='img02'/>
        <img src={m_listImg03}  alt='img03'/>
      </div>

      <div className={style.prdCtSelDiv}>
        {category.map((item) => (
          // (item.CT_NAME == "주파수" || item.CT_NAME == "프로토콜" || item.CT_NAME == "세부 지원 칩 규격" || item.CT_NAME == "기능") &&
          item.CT_NAME === "인식거리" ? null : (
            (
              <div 
              className={style.prdCtSelList}
              // className={`${item.CT_NAME == "세부 지원 칩 규격" ? `${style.prdCtSmallFont} ${style.prdCtSelList}` : style.prdCtSelList}`}
                key={item.CT_IDX} 
                id={'prd-category-' + item.CT_IDX} 
                onClick={(e) => {
                  e.stopPropagation();
                  handleCategoryClick(item.CT_IDX);
                }}
              >
                <span>{item.CT_NAME}</span>
              </div>
            )
          )
        ))}
      
        <div id={style.selectedSpec} className='selectedSpecWrap'>
          {onResultSpecChangeHandler()}
        </div>
      </div>
        
      <div>
        <div id={style.prdListWrap}>
          
          {product.length === 0 ? (
            // <p className={style.searchResult}>검색 결과가 없습니다.</p>
            <div id={style.noneListWrap}>
              <div className={style.searchResult}><span className='blue-font'>검색 결과가 없습니다.</span></div>
              <div className={style.searchResult2}>이 외 다양한 주파수, 통신방식, 사용환경<br></br>RFID/NFC 리더기는 아래 엠포플러스에서 구매 가능합니다.</div>
              <Link to={'http://www.rfemfo.co.kr/main.asp'} target="_blank"><img src={noneList} alt='EMFOPLUS이동하기'/></Link>
            </div>
          ) : (
            product.map((prd) => (
              <MobileProductCard
                key={prd.PRD_IDX}
                prdIdx={prd.PRD_IDX}
                prdName={prd.PRD_NAME}
                prdThumbnail={prd.PRD_THUMBNAIL}
                serverUrl={SERVER_URL}
              />
            ))
          )}
        </div>
            
        <Pagination 
          currentPage={pagingInfo.curPage}
          totalPages={pagingInfo.totalPage}
          onPageChangeHandler={onPageChangeHandler}
        />

      </div>

    </div>
  );

};

export default MobileProductListPage;
