import React, { useEffect, useState } from 'react';
import { get } from '../../utils/api';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Moment from 'moment';
import Pagination from '../../components/Paging/Pagination';
import style from '../../assets/css/commonListPage.module.css'
import faqStyle from './FaqListPage.module.css';
import '../../assets/css/common.css';
import CustomerMenuModal from '../../components/Modal/CustomerMenuModal';

import SideBannerModal from '../../components/Modal/SideBannerModal';

const FaqListPage = () => {
    
  const [faqList, setFaqList] = useState([]);
  const [pagingInfo, setPagingInfo] = useState({});
  const [curPage, setCurPage] = useState(1);
  const [cateName, setCateName] = useState('전체보기');

  // const {cateName} = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setCateName(selectedValue);
    if (selectedValue === '전체보기') {
      // navigate(`/faq/faqAllList`);
      faqAllData();

    } else {
      navigate(`/faq/list/${selectedValue}`);
    }
  };

  useEffect(() => {
    if (cateName === '전체보기') {
      faqAllData();
    } else {
      fetchData(cateName, curPage);
    }
  }, [cateName, curPage]); 

  const fetchData = async(selectedValue, pageNumber) => {
    try {
        const response = await get(`/faq/faqList?faqCate=${selectedValue}&pageIdx=${pageNumber}`);
        setFaqList(response.faqList);
        const {pageInfo} = response;
        pageInfo.totalPage = parseInt(pageInfo.totalPage, 10);
        setPagingInfo(pageInfo);
      } catch(err) {
        console.log(err);
      };
  };

  useEffect(() => {
    fetchData(cateName, curPage);
  }, [cateName, curPage]);

  
  const faqAllData = async() => {
    try {
      const response = await get('/faq/faqAllList', {
        pageIdx: curPage
      });
      setFaqList(response.faqList);
      const {pageInfo} = response;
      pageInfo.totalPage = parseInt(pageInfo.totalPage, 10);
      setPagingInfo(pageInfo);
    } catch(err) {
      console.log(err);
    };
  };

  useEffect(() => {
    faqAllData();
  }, [curPage]);

  const showFaqList = (faqIdx, faqTitle, faqCategory) => {

    // faqInputDate = Moment(faqInputDate).format('YYYY-MM-DD');

    return (
        <tr className={style.tableList} id={'faq-' + faqIdx} key={faqIdx}>
        {/* <td>
            <Link to={`/faq/detail?cateName=${cateName}&faqIdx=${faqIdx}`}>{faqIdx}</Link>
        </td>
        <td>
            <Link to={`/faq/detail?cateName=${cateName}&faqIdx=${faqIdx}`}>{faqTitle}</Link>
        </td> */}
        <td>
            <Link to={`/faq/faqAllDetail/${faqIdx}`}>{faqIdx}</Link>
        </td>
        <td>
            <Link to={`/faq/faqAllDetail/${faqIdx}`}>{faqTitle}</Link>
        </td>
        <td>{faqCategory}</td>
        </tr>
    );
  };

  const onPageChangeHandler = (pageNumber) => {
    setCurPage(pageNumber);
  };

  return (
    <>
      <div className={style.mainTitleDiv}>
        <h2 className={style.mainTitle}>고객센터</h2>
      </div>
      <CustomerMenuModal />

      <div className={style.container}>

      <SideBannerModal />

        <div className={style.title}>FAQ
        
          <a className={faqStyle.selectBox}>
              <select value={cateName} onChange={handleChange}>
                  <option value="전체보기">전체보기</option>
                  <option value="장치연결">장치연결</option>
                  <option value="사용문의">사용문의</option>
                  <option value="결제문의">결제문의</option>
                  <option value="배송문의">배송문의</option>
                  <option value="기타">기타</option>
              </select>
          </a>
        
        </div>
        
        <table className={style.table}>
          <thead>
            <tr>
              <th className='w-10'>NO</th>
              <th className='w-70'>내용</th>
              <th className='w-20'>카테고리</th>
            </tr>
          </thead>
          <tbody>

            {faqList && faqList.map((faq) => (
              showFaqList(faq.FAQ_IDX, faq.FAQ_TITLE, faq.FAQ_CATEGORY)
            ))}
          
          </tbody>
        </table>

        <Pagination
          currentPage={pagingInfo.curPage}
          totalPages={pagingInfo.totalPage}
          onPageChangeHandler={onPageChangeHandler}
        />

      </div>
    </>
  );

};


export default FaqListPage;