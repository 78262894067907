import React, { useEffect, useState } from 'react';
import { get } from '../../../utils/api'
import { Link, useLocation } from "react-router-dom"
import ProductCard from '../../../components/Product/ProductCard';
import style from './ProductListPage.module.css'
import Pagination from '../../../components/Paging/Pagination';

import { listImg01, listImg02, listImg03, noneList } from '../../../assets/images/PrdListPage/prdList';

import SideBannerModal from '../../../components/Modal/SideBannerModal';

const ProductListPage = () => {

  const SERVER_URL = "https://sensetab.co.kr/";
  const [product, setProduct] = useState([]);
  const [spec, setSpec] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [pagingInfo, setPagingInfo] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [searchResult, setSearchResult] = useState(true);
  const [searchList, setSearchList] = useState([]);

  const [visibleCategories, setVisibleCategories] = useState(["주파수", "통신방식", "기능"]);

  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);

  let location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let submitVal = searchParams.get("searchVal");
  
  const getFilteredProducts = async () => {
    try {
      if (submitVal !== undefined && submitVal !== null && submitVal.length > 0) {
        const response = await get('/product/search', {
          searchValue: submitVal,
        });
        if (response !== false && response !== null && response.length > 0) {
          const specIndices = response.map(item => `${item.SPEC_IDX}`);
          setSearchList(specIndices);
          setSearchResult(true);
        } else if (response === false || checkedList === null) {
          setSearchResult(false);
          setCheckedList([]);
        }
      } else {
        setSearchResult(false);
      }
    } catch (error) {
      console.log(error);
      setSearchResult(false);
    }
  };

  useEffect(() => {
    if (searchList.length > 0) {
      const fetchData = async () => {
        try {
          const response = await get('/product/list', {
            checkedList: searchList, 
            pageIdx: curPage
          });
          setProduct(response.prdList);
          setSpec(response.spList);
          setPagingInfo(response.pageInfo);

          searchList.forEach(specIdx => {
            onCheckedItem(true, specIdx);
          });

        } catch (error) {
          console.log(error);  
        };
      }
      fetchData();
    }
  }, [searchList, curPage]);
  const prdData = async () => {
    try {
      const response = await get('/product/list', {
        checkedList: checkedList, 
        pageIdx: curPage
      });
      setProduct(response.prdList);
      setSpec(response.spList);
      setPagingInfo(response.pageInfo);
    } catch (error) {
      console.log(error);  
    };
  };

  useEffect(() => {
    getFilteredProducts();
  }, [submitVal, curPage]);

  useEffect(() => {
    setSearchResult(true);
    prdData();
  }, [checkedList, curPage]);

  const onCheckedItem = (checked, item) => {
    setCheckedList((prev) => {
      const updatedList = checked ? [...prev, item] : prev.filter((el) => el !== item);
      return updatedList;
    });
  };

  const onPageChangeHandler = (pageNumber) => {
    setCurPage(pageNumber);
    prdData();
  };

  const toggleAdditionalOptions = () => {
    setShowAdditionalOptions(!showAdditionalOptions);
  };

  return (
    <div className='container'>

    <SideBannerModal />
      
      <div id='title'>제품검색</div>

      <div className={style.listImages}>
        <img src={listImg01}  alt='img01'/>
        <img src={listImg02}  alt='img02'/>
        <img src={listImg03}  alt='img03'/>
      </div>

      <hr />
      <div className={style.prdSpecWrap}>
        {spec.map((item) => (
          item.CT_NAME === "인식거리" ? null : (
          visibleCategories.includes(item.CT_NAME) && (
            <div className={style.prdCtDiv} key={item.CT_IDX}>
              <div className={style.ctNameDiv}>{item.CT_NAME}</div>
              <div className={style.prdSpecDiv}>
                {item.specs.map((spec) => (
                  <label key={spec.SPEC_IDX}>
                    <input
                      type="checkbox"
                      id={spec.SPEC_IDX}
                      className={style.prdCtList}
                      value={spec.SPEC_IDX}
                      onChange={(e) => {
                        onCheckedItem(e.target.checked, e.target.id);
                      }}
                    />
                    <span key={spec.SPEC_IDX} className={style.specSpan}>
                      {spec.SPEC_NAME}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          )
         )
        ))}
        
        {showAdditionalOptions && (
          <div>
            {spec.map((item) => (
              item.CT_NAME === "인식거리" ? null : (
              !visibleCategories.includes(item.CT_NAME) && (
                <div className={style.prdCtDiv} key={item.CT_IDX}>
                  <div className={style.ctNameDiv}>{item.CT_NAME}</div>
                  <div className={style.prdSpecDiv}>
                    {item.specs.map((spec) => (
                      <label key={spec.SPEC_IDX}>
                        <input
                          type="checkbox"
                          id={spec.SPEC_IDX}
                          className={style.prdCtList}
                          value={spec.SPEC_IDX}
                          onChange={(e) => {
                            onCheckedItem(e.target.checked, e.target.id);
                          }}
                        />
                        <span key={spec.SPEC_IDX} className={style.specSpan}>
                          {spec.SPEC_NAME}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              )
             )
            ))}
          </div>
        )}
      </div>

      <div className={style.detailBtnDiv}>
        <button onClick={toggleAdditionalOptions} className={style.detailBtn}>
          {!showAdditionalOptions ? "+ 상세옵션" : "닫기"}
        </button>
      </div>

        <br />
        <br />
        
        <div id={style.prdListWrap}>
          {product.length === 0 || searchResult === false ? (
            <div id={style.noneListWrap}>
              <div className={style.searchResult}><span className='blue-font'>검색 결과가 없습니다.</span></div>
              <div className={style.searchResult2}>이 외 다양한 주파수, 통신방식, 사용환경<br></br>RFID/NFC 리더기는 아래 엠포플러스에서 구매 가능합니다.</div>
              <Link to={'http://www.rfemfo.co.kr/main.asp'} target="_blank"><img src={noneList} alt='EMFOPLUS이동하기'/></Link>
            </div>
          ) : (
            product.map((prd) => (
              <ProductCard
                key={prd.PRD_IDX}
                prdIdx={prd.PRD_IDX}
                prdName={prd.PRD_NAME}
                prdThumbnail={prd.PRD_THUMBNAIL}
                serverUrl={SERVER_URL}
              />
            ))
          )}
        </div>
            
        <Pagination 
          currentPage={pagingInfo.curPage}
          totalPages={pagingInfo.totalPage}
          onPageChangeHandler={onPageChangeHandler}
        />
    </div>
  );

};

export default ProductListPage;
