import React from 'react';
import { Link } from 'react-router-dom';
import style from '../../assets/css/commonListPage.module.css'
import inquiryStyle from './InquiryResultPage.module.css';
import checkImg from '../../assets/images/checkImg.png'

const InquiryResultPage = () => {

    return (
        <>
        <div className={style.mainTitleDiv}>
            <h2 className={style.mainTitle}>구매/견적문의</h2>
        </div>

        <div className={inquiryStyle.container}>
            <div className={inquiryStyle.checkImage}><img src={checkImg} alt="" /></div>
            <div className={inquiryStyle.success}>
                <p>문의 등록이 완료되었습니다 :)</p>
                <p>영업시간 기준 24시간 이내 처리해 드리겠습니다.</p>
                <p>감사합니다.</p>
            </div>
            <div className={inquiryStyle.sucBtn}> 
                <Link to={'/'}>홈으로 돌아가기</Link>
            </div>
        </div>
        </>

    );

};

export default InquiryResultPage;