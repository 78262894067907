import React from 'react';
import { Link } from 'react-router-dom';

import { section00, section01, section02, section03, section04, section05, section06, section07 } from '../../../assets/images/mainImages'

import SideBannerModal from '../../../components/Modal/SideBannerModal';

const ProductIntroPage = () => {
  
    const divStyle={
        textAlign : "center",
        width: "100%",
        marginBottom: "150px",
    };

    const imgStyle={
        textAlign: "center",
        width: "100%",
    };

  return (
    <>
      <div id='main-container'>

      <SideBannerModal />
      
        <div style={divStyle}>
          <img src={section00} alt="제품소개이미지" />
          <img src={section01} alt="제품소개이미지1" />
          <img src={section02} alt="제품소개이미지2" />
          <img src={section03} alt="제품소개이미지3" />
          <img src={section04} alt="제품소개이미지4" />
          <img src={section05} alt="제품소개이미지5" />
          <img src={section06} alt="제품소개이미지6" />
          <img src={section07} alt="제품소개이미지7" />
        </div>
      </div>
    </>
    
  );

};

export default ProductIntroPage;
