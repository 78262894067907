import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from '../../assets/css/commonListPage.module.css'

const CustomerMenuModal = () => {
  
  const location = useLocation();
  const currentPathname = location.pathname;

  return (
    <>
      <div className={style.customerMenusDiv}>
        <div className={style.customerMenus}>
          <Link to={'/notice/list'} className={currentPathname.includes('/notice') ? style.activeTab : ''}>공지사항</Link>
          <Link to={'/faq/faqAllList'} className={currentPathname.includes('/faq') ? style.activeTab : ''}>FAQ</Link>
          <Link to={'/remotesupport'} className={currentPathname.includes('/remotesupport') ? style.activeTab : ''}>원격지원</Link>
          <Link to={'http://www.rfemfo.co.kr/Custom/Buy.asp'} target="_blank">결제하기</Link>
        </div>
      </div>
    </>
  );
};

export default CustomerMenuModal;