import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import BuyOrderPage from "./pages/BuyPage/BuyOrderPage"; // BuyOrderPage ������ �־�� ��
import HttpRedirect from 'react-https-redirect';

// GET �Ű������� ó���ϰ� BuyOrderPage ������Ʈ�� �������ϴ� �Լ�
const processGetParameters = () => {
  // ���� URL ��ΰ� "/buy/order"���� Ȯ��
  if (window.location.pathname === "/buy/order") {
    const queryParams = new URLSearchParams(window.location.search);
    const data = {};

    queryParams.forEach((value, key) => {
      data[key] = value;
    });

    // BuyOrderPage ������Ʈ�� �������ϰ� ó���� �����͸� props�� ����
    ReactDOM.render(<BuyOrderPage data={data} />, document.getElementById("root"));
  }
};

// GET �Ű������� ó���ϴ� �Լ� ȣ��
processGetParameters();

// ������ ��쿡�� App ������Ʈ�� ������
ReactDOM.render(<HttpRedirect>
      <App />
    </HttpRedirect>, document.getElementById("root"));