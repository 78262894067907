import React, { useEffect, useState } from 'react';
import { get } from '../../utils/api';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import style from './MobileMainPage.module.css'

import { m_mainPage01, m_mainPage02, m_mainPage03, m_mainEPRImg, m_mainList01_Off, m_mainList01_On, m_mainList02_Off, m_mainList02_On, m_mainList03_Off, m_mainList03_On } from '../../assets/images/mainImagesMobile'
import { m_btNotice_Off, m_btNotice_On, m_btPI_Off, m_btPI_On, m_btIntro_Off, m_btIntro_On, m_btInfo_Off, m_btInfo_On} from '../../assets/images/mainImagesMobile'
import { m_prdUseMain, m_prdUse01, m_prdUse02, m_prdUse03, plusBtn } from '../../assets/images/mainImagesMobile'
import SideBannerModal from '../../components/Modal/SideBannerModal';


import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Main = () => {

  const [mbResult, setMbResult] = useState([]);
  const [mainProduct, setMainProduct] = useState([]);

  const SERVER_URL = "https://sensetab.co.kr/";

  const mbData = async() => {
    try {
      const response = await get('/main/test');
      setMbResult(response);
    } catch(err) {
      console.log(err);
    };
  };

  const mainProductData = async() => {
    try {
      const response = await get('/main/mobile/product');
      setMainProduct(response);
    } catch(err) {
      console.log(err);
    };
  };

  useEffect(() => {
    mbData();
    mainProductData();
  }, []);

  const showMainProduct = (prdImgPath, idx) => {
  
    return (
        <Link to={`/product/detail/${idx}`}>
          <img src={SERVER_URL + prdImgPath} alt='대표이미지'></img>
        </Link>
    );
  };

  return (
    <>
      <div id={style.mainImg}>
      <Swiper
          slidesPerView={1}
          initialSlide={0} // 시작 위치 값
          centeredSlides={true}
          rewind={true}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
         }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide><div><Link to={'/'}><img src={m_mainPage01} alt="메인이미지" /></Link></div></SwiperSlide>
          <SwiperSlide><div><Link to={'http://rfemfo.co.kr/Tag/MakeTAG.asp'} target="_blank"><img src={m_mainPage02} alt="메인이미지2" /></Link></div></SwiperSlide>
          <SwiperSlide><div><Link to={'http://rfemfo.co.kr/Reader/RFID_Reader.asp'} target="_blank"><img src={m_mainPage03} alt="메인이미지3" /></Link></div></SwiperSlide>
        </Swiper>
      </div>
      
      <div id={style.mainContainer}>

        <SideBannerModal />

        <div>
          <h1 className={style.textCenter}>EPR RFID 리더기</h1>
          <div id={style.mainReaderWrap}>
            <div id={style.readerMainImg}>
              <img src={m_mainEPRImg} alt='메인리더기' />
            </div>
            <div id={style.readers}>
              <div className={style.infoDiv}>
                <Link to={'/product/list'}>
                  <img className={style.hoverOff} src={m_mainList01_Off} alt='주파수별 리더기'/>
                  <img className={style.hoverOn} src={m_mainList01_On} alt='주파수별 리더기'/>
                </Link>
              </div>
              <div className={style.infoDiv}>
                <Link to={'/product/list'}>
                  <img className={style.hoverOff} src={m_mainList02_Off} alt='통신방식별 리더기'/>
                  <img className={style.hoverOn} src={m_mainList02_On} alt='통신방식별 리더기'/>
                </Link>
              </div>
              <div className={style.infoDiv}>
                <Link to={'/product/list'}>
                  <img className={style.hoverOff} src={m_mainList03_Off} alt='사용 용도별 리더기'/>
                  <img className={style.hoverOn} src={m_mainList03_On} alt='사용 용도별 리더기'/>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h1 className={style.textCenter}>대표 PRODUCT</h1>

          <div className={style.mainPrdDiv}>
            {mainProduct && mainProduct.map((mp) => (
              mp.PRD_IMG_PATH && mp.PRD_IDX ? showMainProduct(mp.PRD_IMG_PATH, mp.PRD_IDX) : null
              ))}
          </div>
          <div className={style.mainPlusBtn}>
            <Link to={'/product/list'}><img src={plusBtn} alt='더보기버튼'/></Link>
          </div>
        </div>

        <div>

          <div className={style.subImgDivMain}>
            <img src={m_prdUseMain}  alt='제품활용분야'/>
          </div>

          {/* <h1 className={style.textCenter}>제품 활용 분야</h1>

          <div className={style.subImgDiv}>
            <img src={m_prdUse01}  alt='제품활용분야'/>
            <img src={m_prdUse02}  alt='제품활용분야2'/>
          </div>
          <div className={style.subImgDiv2}>
            <img src={m_prdUse03}  alt='제품활용분야-소개글'/>
          </div> */}
        </div>

        <div id={style.infoWrap}>
          <div className={style.infoDiv}>
            <Link to={'/notice/list'}>
              <img className={style.hoverOff} src={m_btNotice_On} alt='공지사항'/>
              <img className={style.hoverOn} src={m_btNotice_Off} alt='공지사항'/>
            </Link>
          </div>
          <div className={style.infoDiv}>
            <Link to={'/inquiry/insert'}>
              <img className={style.hoverOff} src={m_btPI_On} alt='구매/견적문의'/>
              <img className={style.hoverOn} src={m_btPI_Off} alt='구매/견적문의'/>
            </Link>
          </div>
          <div className={style.infoDiv}>
            <Link to={SERVER_URL + mbResult} target="_blank">
              <img className={style.hoverOff} src={m_btIntro_On} alt='제품소개서'/>
              <img className={style.hoverOn} src={m_btIntro_Off} alt='제품소개서'/>
            </Link>
          </div>
          <div className={style.infoDiv}>
            <Link to={'/product/list'}>
              <img className={style.hoverOff} src={m_btInfo_On} alt='자료실'/>
              <img className={style.hoverOn} src={m_btInfo_Off} alt='자료실'/>
            </Link>
          </div>

        </div>
      </div>
      
    </>
  );
};

export default Main;